import React from "react";
import { Dashboard, Payments, Settings } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import { actions } from "../../constants/permission";
import HubSharpIcon from "@mui/icons-material/HubSharp";
import PaidSharpIcon from "@mui/icons-material/PaidSharp";

const Menus = [
  {
    name: "Dashboard",
    icon: <Dashboard />,
    path: "/app/dashboard",
    act: actions.readAll,
    feat: "All",
  },
  {
    name: "Users",
    icon: <PersonIcon />,
    path: "/app/user",
    act: actions.readAll,
    feat: "All",
  },
  {
    name: "Customer Feedback",
    icon: <HubSharpIcon />,
    path: "/app/customer-feedback",
    act: actions.readAll,
    feat: "All",
  },
  {
    name: "Plan",
    icon: <Payments />,
    path: "/app/payments",
    act: actions.readAll,
    feat: "All",
  },
  // {
  //   name: "Prizing Card",
  //   icon: <PaymentSharpIcon/>,
  //   path: "/app/palncard",
  //   act: actions.readAll,
  //   feat: "All",
  // },
  {
    name: "Payment List",
    icon: <PaidSharpIcon />,
    path: "/app/payment-list",
    act: actions.readAll,
    feat: "All",
  },
  {
    name: "Setting",
    icon: <Settings />,
    path: "/app/setting",
    act: actions.readAll,
    feat: "All",
  },
];

export default Menus;

import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Hidden,
  IconButton,
  Pagination,
  Grid,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import FloatingButton from "components/FloatingButton";
import { useHistory } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import ListSkeleton from "../../components/Skeleton/ListSkeleton";
import ModalContent from "./components/ModalContent";
import styled from "@emotion/styled";
import Input from "components/Input";
import useDebounce from "useDebounce";
import ActiveCreditModal from "./components/ActiveCreditModal";
import { post } from "utils/api";

const FilterBox = styled(Box)(() => ({
  width: "100%",
  marginTop: 30,
  marginBottom: 20,
  display: "flex",
  justifyContent: "space-between",
}));

export default function User() {
  const [credit, setCredit] = useState(0);
  const [userId, setuserId] = useState("");
  const [open, setOpen] = useState(false);
  const [activeCredit, setActiveCredit] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filter, setFilter] = useState({
    keyword: "",
    page: 1,
  });
  const API_URL = process.env.REACT_APP_API_URL;
  const debouncedInputValue = useDebounce(filter?.keyword, 1500);

  const AllUserApi = async () => {
    const body = {
      user_id: userId || "",
      start_date: startDate || "",
      end_date: endDate || "",
    };
    setLoading(true);
    const response = await post(
      `${API_URL}/admin/get-all-users?search_value=${filter.keyword}&page=${filter.page}&per_page=10`,
      body
    );
    console.log(response, "...");
    setUsers(response?.data?.data);
    setActiveCredit(false);
    setStartDate("");
    setEndDate("");
    setLoading(false);
  };

  useEffect(() => {
    AllUserApi();
  }, [debouncedInputValue, filter?.page]);

  const handleChangePagination = (e, val) => {
    const newRoute = `/app/user?page=${val}`;
    history.push(newRoute);

    // Update the filter state
    setFilter({
      ...filter,
      page: val,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleActiveCreditClose = () => {
    setActiveCredit(false);
  };
  const handleChangeFilter = ({ target }) => {
    const { name, value } = target;

    setFilter({
      ...filter,
      [name]: value,
      page: 1,
    });
  };

  return (
    <div className={open ? "modal-open" : ""}>
      <Card>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          All User
        </Typography>

        <FilterBox>
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item lg={6} sm={12} xs={12}>
              <Input
                label="Search"
                name="keyword"
                placeholder="Search"
                value={filter.keyword ?? ""}
                onChange={handleChangeFilter}
              />
            </Grid>
          </Grid>
        </FilterBox>

        {loading ? (
          <ListSkeleton />
        ) : (
          <Box style={{ paddingTop: "20px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <Hidden smDown>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Company Name</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Keywords</TableCell>
                    <TableCell>Posts Credits</TableCell>
                    <TableCell align="right">Active credits</TableCell>
                  </Hidden>
                  <TableCell align="right">Option</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.total_results === 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={7}>
                      No Data
                    </TableCell>
                  </TableRow>
                )}
                {Array.isArray(users?.users) &&
                  users?.users?.map((item, i) => {
                    console.log(item, "item,,11");
                    return (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <Hidden smDown>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.email}</TableCell>
                          <TableCell style={{ textTransform: "capitalize" }}>
                            {item.company_name ? item.company_name : "-"}
                          </TableCell>
                          <TableCell>{item.expiration_date}</TableCell>

                          <TableCell
                            style={{ cursor: "pointer", color: "blue" }}
                            onClick={() => {
                              setuserId(item?.id);
                              setCredit(item.credit[0]);
                              setOpen(true);
                            }}
                          >
                            {item.credit[0].available_credit}/
                            {item.credit[0].total_credit}
                          </TableCell>

                          <TableCell
                            style={{ cursor: "pointer", color: "blue" }}
                            onClick={() => {
                              setuserId(item?.id);
                              setCredit(item.credit[1]);
                              setOpen(true);
                            }}
                          >
                            {item.credit[1].available_credit}/
                            {item.credit[1].total_credit}
                          </TableCell>

                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {!item?.activity_credit && (
                                <div
                                  onClick={() => {
                                    setuserId(item?.id);
                                    setActiveCredit(true);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    color: "blue",
                                  }}
                                >
                                  View
                                </div>
                              )}
                              {item?.activity_credit && (
                                <div
                                  style={{
                                    backgroundColor: "#FFFFFF",
                                    borderRadius: "8px",
                                    padding: "5px 8px",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  <p>
                                    Keywords : {item?.activity_credit?.keywords}
                                  </p>
                                  <p>
                                    posts Credit :{" "}
                                    {item?.activity_credit?.posts}
                                  </p>
                                </div>
                              )}
                            </div>
                          </TableCell>
                        </Hidden>
                        <TableCell align="right">
                          <IconButton
                            onClick={() =>
                              history.push(
                                `/app/user/update/${item.id}?user_type=${item?.user_type}`,
                                {
                                  state: { user_type: item?.user_type },
                                }
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>

            <Pagination
              sx={{ mt: 1 }}
              page={filter?.page}
              count={users?.total_pages}
              onChange={handleChangePagination}
            />
          </Box>
        )}
        <FloatingButton onClick={() => history.push("/app/user/create")} />
        <ModalContent
          open={open}
          onClose={handleClose}
          credit={credit}
          id={userId}
          AllUserApi={AllUserApi}
        />
        <ActiveCreditModal
          open={activeCredit}
          onClose={handleActiveCreditClose}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          AllUserApi={AllUserApi}
        />
        {/* <DialogConfirm
          title="Delete Data"
          content="Are you sure want to delete this data?"
          open={confirmDelete}
          onClose={() => setConfirmDelete(false)}
          onSubmit={handleDelete}
        /> */}
      </Card>
    </div>
  );
}
